@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

body {
  font-weight: 400;
  font-family: 'Prompt-Regular';
  background-color: #383838;
}

/* .container {
  width: 1000px;
  max-width: 100%;
  background-color: #fff;
} */

.twindex {
  margin-bottom: 10px;
}

.twindex .header {
  overflow: hidden;
  /* padding: 10px 0px; */
}

.twindex .content {
  background-color: #f0f0f0;
  padding: 15px;
}

.twindex .content .index-head {
  text-align: center;
  background-color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 5px;
  border-bottom: 2px solid #a6caff;
}

.twindex .content .index-cont {
  background-color: #fff;
  padding: 15px;
}

address, dl, ol, ul {
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
}

.twindex .content .index-cont .col-left ul {
  padding: 0;
}

.twindex .content .index-cont .col-left ul li.info, .twindex .content .index-cont .col-left ul li.taiex {
  display: flex;
  vertical-align: middle;
  align-items: center;
  height: 50px;
  font-weight: 700;
}

.twindex .content .index-cont .col-left ul li.active {
  background-color: #cde1ff;
}

.twindex .content .index-cont .col-left ul li {
  list-style: none;
  border-bottom: 1px solid #003685;
  padding-left: 5px;
  cursor: pointer;
}

.twindex .content .index-cont .col-left ul li.tw50 {
  padding: 10px 5px;
}

.twindex .content .index-cont .col-left ul li.tw50 .down .c, .twindex .content .index-cont .col-left ul li.tw50 .down .p {
  color: red;
}

.twindex .content .index-cont .col-left ul li.tw50 .up .c, .twindex .content .index-cont .col-left ul li.tw50 .up .p {
  color: #090;
}

.twindex .content .index-cont .col-left ul li.cg100 {
  padding: 10px 5px;
}

.twindex .content .index-cont .col-left ul li.cg100 .down .c, .twindex .content .index-cont .col-left ul li.cg100 .down .p {
  color: red;
}

.twindex .content .index-cont .col-left ul li.cg100 .up .c, .twindex .content .index-cont .col-left ul li.cg100 .up .p {
  color: #090;
}

.twindex .content .index-cont .col-right .tabs ul {
  padding: 0;
  margin-top: 0;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  border-bottom: 0;
  display: flex;
}

.twindex .content .index-cont .col-right .tabs ul li.active {
  background-color: #d9e8ff;
  color: #000;
}

.twindex .content .index-cont .col-right .tabs ul li:first-child {
  border-radius: 0 4px 4px 0;
}

.twindex .content .index-cont .col-right .tabs ul li {
  list-style: none;
  font-weight: 400;
  display: block;
  flex: 1;
  border: 1px solid #d9e8ff!important;
  text-align: center;
  align-items: center;
  line-height: 30px;
  color: #666;
  cursor: pointer;
}

.twindex .content .index-cont .col-right .chart-info {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1;
}

.twindex .content .index-cont .col-right .chart-info .t {
  margin-right: 15px;
}

.twindex .content .index-cont .col-right .chart-info .TWSE_TXT1 {
  margin-right: 5px;
}

.twindex .content .index-cont .col-right .chart-info .d, .twindex .content .index-cont .col-right .chart-info .i, .twindex .content .index-cont .col-right .chart-info .p {
  margin-right: 15px;
}

.twindex .content .index-cont .col-right .chart-info .down {
  color: red;
}

.twindex .content .index-cont .col-right .chart-info .up {
  color: #090;
}

.twindex .content .index-cont .col-right label {
  margin-bottom: 0;
}

.twindex .content .index-cont .col-right .today-result {
  margin-top: 20px;
}

.twindex .content .index-cont .col-right .today-result .table {
  margin: 0 auto;
  width: 500px;
  max-width: 100%;
}

.history {
  padding: 0 15px 15px;
  background-color: #f0f0f0;
}

.history .history-content {
  background-color: #fff;
  padding: 10px;
}

.history .history-content h4 {
  color: #005790;
}

.history .history-content .table tr td, .history .history-content .table tr th {
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #005790;
  color: #005790;
}